import HeaderContact from '../../components/HeaderContact';  
import Footer from '../../components/Footer';
function ContactUs() { 
	return( 
	<>  
    
    <HeaderContact /> 
    <div class="breadcrumbs">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
        <div class="breadcrumbs-content">
          <h1 class="page-title">Contact Us</h1>
        </div>
        <ul class="breadcrumb-nav">
          <li>
            <a href="/">Home</a>
          </li>
          <li>Contact</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<section id="contact-us" class="contact-us section">
  <div class="container">
    <div class="contact-head">
      <div class="row">
        <div class="col-lg-5 col-12">
          <div class="single-head">
            <div class="contant-inner-title">
              <h2>Our Contacts & Location</h2>
              <p>You Can Find Us At The Following Location
              </p>
            </div>
            <div class="single-info">
              <h3>Head Office Address:</h3>
              <ul>
                <li>7/96, North Street, Athisayapuram, VK Pudur (PO) Tenkasi – 627861</li>
              </ul>
            </div>
            <div class="single-info">
              <h3>Chennai Address:</h3>
              <ul>
                <li> 1B, Block 1, Neelkamal Apartment, Kazhipattur, Chennai - 603103</li>
              </ul>
            </div>
            <div class="single-info">
              <h3>Contact Info</h3>
              <ul> 
                <li>
                  <a href="#">
                    <span class="__cf_email__" data-cfemail="34514c5559445851745d5a525b1a575b59">support@s3remotica.com</span>
                  </a>
                </li>
                <li>
                  <a href="tel:+91 8344715078">+91 8344715078</a>
                </li>
              </ul>
            </div>
         
          </div>
        </div>
        <div class="col-lg-7 col-12">
          <div class="form-main">
            <div class="form-title">
              <h2>Get in Touch</h2>
              <p>Give us your contact details, and we will reach out to you as soon as possible!</p>
            </div>
            <form class="form" method="post" action="#">
              <div class="row">
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <input name="name" type="text" placeholder="Your Name" required="required"/>
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <input name="subject" type="text" placeholder="Your Subject" required="required"/>
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <input name="email" type="email" placeholder="Your Email" required="required"/>
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <input name="phone" type="text" placeholder="Your Phone" required="required"/>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group message">
                    <textarea name="message" placeholder="Your Message"></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group button">
                    <button type="submit" class="btn ">Send Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <Footer/>

  </> 
  ); 
  } 
  export default ContactUs;