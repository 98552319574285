import React from 'react';
import Header from '../../components/Header';   
import Footer from '../../components/Footer';  
function Home() { 
	return( 
	<>
  
    <Header /> 

    <section id="home" class="hero-area style1">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12 col-12">
        <div class="hero-content wow fadeInLeft" data-wow-delay=".3s">
          <h1>Transforming Ideas into Scalable Solutions<span>.</span></h1>
          <p>Specializing in mobile, web, and website development for businesses, we also offer HRMS solutions, resource outsourcing and customer support solutions.</p>
          <div class="button">
            <a href="/ContactUs" class="btn">Contact Us </a> 
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-12">
        <div class="hero-image wow fadeInRight" data-wow-delay=".4s">
          <img src="assets/images/banner.svg" alt="S3 Remotica" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq section" id="Services">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title"> 
          <h2 class="wow fadeInUp" data-wow-delay=".4s">Services Tailored for you<span>.</span></h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                <span class="title">
                Custom Software Development</span>
                <i class="lni lni-plus"></i>
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>We specialize in developing bespoke software solutions tailored to meet your business needs. Whether you are a start-up, small business, or large enterprise, our team of experienced developers and engineers work closely with you to deliver high-quality, scalable, and secure software that helps streamline your operations and drive growth.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <span class="title">
                Software Consulting
                </span>
                <i class="lni lni-plus"></i>
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>We offer expert software consulting services to help your business leverage the right technology for success. Whether you’re looking to implement new software, optimize your current systems, or need guidance on a custom software development project, our team of experienced consultants is here to provide strategic insights and practical solutions tailored to your needs.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <span class="title">
                Ongoing Maintenance and Support</span>
                <i class="lni lni-plus"></i>
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>Our commitment to your success doesn’t end when your software goes live. We provide comprehensive ongoing maintenance and support services to ensure your software remains efficient, secure, and up-to-date. Our proactive approach ensures that your systems continue to perform at their best as your business grows and evolves.</p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <span class="title">
                HRMS Consulting and Streamlining</span>
                <i class="lni lni-plus"></i>
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>We offer expert HRMS (Human Resource Management System) consulting and streamlining services to help businesses optimize their human resources operations through technology. Our team of consultants specializes in analyzing, selecting, and implementing HRMS solutions that simplify HR processes, improve efficiency, and enhance the overall employee experience.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <span class="title">
                Customer Support as a Service
                </span>
                <i class="lni lni-plus"></i>
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>We understand that exceptional customer support is vital for building strong relationships and retaining loyal customers. Our Customer Support as a Service (CSaaS) solution is designed to help businesses offer seamless, round-the-clock support without the need for an in-house team. We provide trained professionals who handle all customer queries, technical issues, and feedback with efficiency and empathy.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <span class="title">
                Software Developer Outsourcing

                </span>
                <i class="lni lni-plus"></i>
              </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>We offer expert Software Developer Outsourcing services to help businesses build and scale their development teams effortlessly. Whether you're looking to augment your existing team or need a dedicated group of developers to tackle an entire project, we provide highly skilled professionals ready to deliver results.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="about" class="about section">
  <div class="container">
  <div class="row">
      <div class="col-12">
        <div class="section-title"> 
          <h2 class="wow fadeInUp" data-wow-delay=".4s">We provide courses that upskill you<span>.</span></h2>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12 col-12">
        <div class="image wow fadeInLeft" data-wow-delay=".3s">
          <img class="main-image" src="assets/images/popular_courses_1.svg" alt="S3 Remotica" />
          <div class="img2"></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-12">
        <div class="content wow fadeInRight" data-wow-delay=".5s">
          <div class="heading">
            <h2>Specialize in high-demand technical courses</h2>
            <p>A well-structured curriculum tailored to industry demands ensures that learners acquire relevant skills. It focuses on practical knowledge and up-to-date content, preparing students for real-world challenges.</p>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</section>
<section class="services style3 section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title"> 
          <h2 class="wow fadeInUp" data-wow-delay=".4s">Why we're your best choice<span>.</span></h2>
        </div>
      </div>
    </div>
    <div class="single-head">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service wow fadeInUp" data-wow-delay=".2s">
            <img src="assets/images/s1.svg" alt="S3 Remotica" />
            <h3>Industry Expertise in Businesses</h3>
            <p>We specialize in building digital solutions tailored specifically for businesses across a wide range of industries, including equipment, IT, vehicles, real estate, and fashion rentals.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service wow fadeInUp" data-wow-delay=".4s">
            <img src="assets/images/s2.svg" alt="S3 Remotica" />
            <h3>Full-Service Development Solutions</h3>
            <p>From mobile and web application development to website creation and resource outsourcing, we offer end-to-end services to cover all your digital needs.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service wow fadeInUp" data-wow-delay=".6s">
            <img src="assets/images/s3.svg" alt="S3 Remotica" />
            <h3>Seamless <br/>
            User Experience</h3>
            <p>We believe in long-term partnerships. Our 24/7 customer support team ensures your platform runs smoothly, resolving technical issues, and addressing user inquiries promptly.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service wow fadeInUp" data-wow-delay=".2s">
            <img src="assets/images/s4.svg" alt="S3 Remotica" />
            <h3>Future-Ready Technology</h3>
            <p>Our clients have experienced tangible business improvements, from streamlined operations to higher customer satisfaction and increased bookings. We have a portfolio of success stories and case studies.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service wow fadeInUp" data-wow-delay=".4s">
            <img src="assets/images/s5.svg" alt="S3 Remotica" />
            <h3>Dedicated Customer Support</h3>
            <p>We stay ahead of industry trends, using the latest technologies and tools to ensure your platform is not just up-to-date but future-ready. Our team continuously innovates to keep your business competitive in an evolving market.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="single-service wow fadeInUp" data-wow-delay=".6s">
            <img src="assets/images/s6.svg" alt="S3 Remotica" />
            <h3>Proven Track Record</h3>
            <p>User experience is at the heart of what we do. We design intuitive, responsive interfaces that make renting easy for both businesses and customers, increasing engagement, satisfaction, and conversions.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
 

<section id="product" class="about section">
  <div class="container">
  <div class="row">
      <div class="col-12">
        <div class="section-title"> 
          <h2 class="wow fadeInUp" data-wow-delay=".4s">Innovations we Deliver<span>.</span></h2>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12 col-12">
        <div class="image wow fadeInLeft" data-wow-delay=".3s">
          <img class="main-image" src="assets/images/smartstay_1.svg" alt="S3 Remotica" />
          <div class="img2"></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-12">
        <div class="content content-1 wow fadeInRight" data-wow-delay=".5s">
          <div class="heading">
            <h2>Streamlining PG Management with an All-in-One Solution</h2>
            <p>SmartStay is an B2B SaaS product that allows PGs, hostels and service homes management where the admin can manage their customers, rooms, vendors and other services.</p>
          </div>
        
        </div>
      </div>
    </div>
    <div class="row align-items-center about-mt-100"> 
      <div class="col-lg-6 col-md-12 col-12">
        <div class="content content-2 wow fadeInRight" data-wow-delay=".5s">
          <div class="heading">
            <h2>Empower Your Rental Business with LaptoLease</h2>
            <p>LaptoLease is an B2B SaaS product that businesses to rent laptops and other related parts and can manage their customers, inventory, reports and expenses.</p>
          </div>  
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-12">
        <div class="image wow fadeInLeft" data-wow-delay=".3s">
          <img class="main-image" src="assets/images/laptolease_1.svg" alt="S3 Remotica" />
          <div class="img2"></div>
        </div>
      </div>
    </div>
  </div>
</section>
  
<section id="testimonials" class="section testimonials">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title"> 
          <h2 class="wow fadeInUp" data-wow-delay=".4s">What people say about us<span>.</span></h2>
        </div>
      </div>
    </div>
    <div class="slider-head">
  <div class="row testimonial-slider">
    <div class="col-lg-6 col-md-6 col-12">
      <div class="single-testimonial">
        <div class="top-section">
          <img src="assets/images/kubera-logo.jpeg" alt="S3 Remotica"/>
          <h3> Krishnakumar Sriramulu <span>Kubera Financial</span>
          </h3>
          <ul class="rating">
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
          </ul>
        </div>
        <p>As a client I am very happy that my website was developed on time and with the exact ask. My business is running now . Vijay and Immanuvel who worked for my website are very talented . I endorse this team for any endeavor. Congratz Immanuvel and team</p>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
      <div class="single-testimonial">
        <div class="top-section">
          <img src="assets/images/comtal-logo.jpg" alt="S3 Remotica"/>
          <h3>Elango <span>Comtal Technologies</span>
          </h3>
          <ul class="rating">
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
            <li>
              <i class="lni lni-star-filled"></i>
            </li>
          </ul>
        </div>
        <p>We wanted to take a moment to sincerely thank you for your hard work and dedication in creating the application for our laptop rental business. Your expertise and attention to detail have made a significant impact on the functionality and user experience of the platform. </p>
      </div>
    </div>   
    
  </div>
</div>
  </div>
</section> 

<section class="call-action">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-12">
        <div class="text">
          <h2>Need a Custom Solution?</h2>
          <p>We have the confidence and expertise to challenge the status-quo. Let’s collaborate to take your product to new heights.</p>
          
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="button">
            <a href="/ContactUs" class="btn">Contact Us </a> 
          </div>
      </div>
    </div>
  </div>
</section>
  

  
    <Footer/>
        
  </> 
  ); 
  } 
  export default Home;