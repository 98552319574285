import React from 'react';

function HeaderContact () {
  return (
  <>
  
  <header class="header navbar-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="nav-inner">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand style3" href="index.html">
              <img src="assets/images/s3-logo-1.svg" alt="Logo" />
            </a>
            <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
              <ul id="nav" class="navbar-nav ms-auto">
                <li class="nav-item">
                  <a href="/">Home</a>
                </li> 
                <li class="nav-item">
                  <a href="/">Services</a>
                </li>
                <li class="nav-item">
                  <a href="/" >Products</a>
                </li>
                <li class="nav-item">
                  <a href="/">Testimonials</a>
                </li> 
              </ul>
            </div>
            <div class="button add-list-button">
              <a href="#" class="btn">Contact Us</a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>
  </>
);
}

export default HeaderContact;