import React from 'react';

function  Footer () {
  return (
  <>
   
   
<footer class="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="single-footer f-about">
            <div class="logo">
              <a href="index.html">
                <img src="assets/images/s3-logo-footer.svg" alt="#" />
              </a>
            </div>
            <p>Empowering Digital Solutions, Simplifying Your Business</p>
            
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-12">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
              <div class="single-footer f-link">
                <h3>Product</h3>
                <ul> 
                  <li>
                    <a href="javascript:void(0)">About Us</a>
                  </li> 
                  <li>
                    <a href="javascript:void(0)">Services</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Contact Us</a>
                  </li> 
                </ul>
              </div>
            </div> 
         
            <div class="col-lg-4 col-md-6 col-12">
              <div class="single-footer f-link">
                  <h3>Head Office Address</h3>
                  <h5>7/96, North Street, Athisayapuram, VK Pudur (PO) Tenkasi – 627861</h5>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="single-footer f-link">
                  <h3>Chennai Address</h3>
                  <h5>1B, Block 1, Neelkamal Apartment, Kazhipattur, Chennai - 603103</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="copyright-text">© 2024 S3 Remotica Technologies. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</footer>
<a href="/" class="scroll-top">
  <i class="lni lni-chevron-up"></i>
</a>

  </>
);
}

export default Footer;