
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home"; 
import ContactUs from "./pages/ContactUs/ContactUs";   
 

const routes = (
  <Router>    
    <Routes>
      <Route path='/' element={<Home/>} />  
      <Route path='/ContactUs' element={<ContactUs/>} />    
      
    </Routes>
 </Router> )
function App() {
  return (
    <>
        {routes}  
    </>
  );
}

export default App;
